import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { notificationBus } from './core/infrastructure/notification-bus/notification-bus'
import hsPass from './core'
import i18n from './i18n'
import ElementPlus from 'element-plus'
import ru from 'element-plus/es/locale/lang/ru'
import DynamicTag from './core/pass/components/pass-elements/dynamic-tag'

const app = createApp(App)
app.config.globalProperties.$notificationBus = notificationBus
app.component('dynamic-tag', DynamicTag)
app.use(router)
app.use(store)
app.use(i18n)
app.use(hsPass)

app.use(ElementPlus, {
  locale: ru,
})

app.mount('#app')
