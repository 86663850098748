<template>
  <span v-if="jsonText">
    <!-- fragment -->
    <span v-if="jsonText && jsonText.length === 0">
      <i class="el-icon-info text-hs-secondary mr-1" />
      {{ $t('pass.noStepText') }}.
    </span>
    <span v-if="jsonText && jsonText.length > 0">
      <span v-for="(item, idx) in jsonText" :key="idx">
        <dynamic-tag v-if="item.e && item.e !== 'hs'" :tag="item.e" :item="item">
          <step-render :uid="uid" :json-text="item._" />
        </dynamic-tag>
        <hs-input
          v-else-if="item.e && item.e === 'hs' && getThisField(item['@d'], 'type') === 'text'"
          v-show="getThisFieldVisibility(item['@d'])"
          :id="`step-field-${getThisField(item['@d'], 'id')}`"
          :value="getThisField(item['@d'], 'value')"
          :placeholder="getThisField(item['@d'], 'name')"
          :autocomplete="getThisField(item['@d'], 'options').autocomplete"
          @set-value="setValue($event, item['@d'])"
        />
        <hs-textarea
          v-else-if="item.e && item.e === 'hs' && getThisField(item['@d'], 'type') === 'textarea'"
          v-show="getThisFieldVisibility(item['@d'])"
          :id="`step-field-${getThisField(item['@d'], 'id')}`"
          :value="getThisField(item['@d'], 'value')"
          :placeholder="getThisField(item['@d'], 'name')"
          @set-value="setValue($event, item['@d'])"
        />
        <hs-table
          v-else-if="item.e && item.e === 'hs' && getThisField(item['@d'], 'type') === 'table'"
          v-show="getThisFieldVisibility(item['@d'])"
          :id="`step-field-${getThisField(item['@d'], 'id')}`"
          :value="getThisField(item['@d'], 'value')"
          :placeholder="getThisField(item['@d'], 'name')"
          :options="getThisField(item['@d'], 'options')"
          :variant="tableType"
          :tooltipsText="{ clearAllFields: $t('components.table.cleanAllFields') }"
          @set-value="setValue($event, item['@d'])"
        />
        <hs-checkbox
          v-else-if="item.e && item.e === 'hs' && getThisField(item['@d'], 'type') === 'checkbox'"
          v-show="getThisFieldVisibility(item['@d'])"
          :id="`step-field-${getThisField(item['@d'], 'id')}`"
          :value="getThisField(item['@d'], 'value')"
          :placeholder="getThisField(item['@d'], 'name')"
          @set-value="setValue($event, item['@d'])"
        />
        <hs-number
          v-else-if="
            item.e &&
            item.e === 'hs' &&
            getThisField(item['@d'], 'type') === 'number' &&
            !getThisField(item['@d'], 'options').numberFormula
          "
          v-show="getThisFieldVisibility(item['@d'])"
          :id="`step-field-${getThisField(item['@d'], 'id')}`"
          :value="getThisField(item['@d'], 'value')"
          :placeholder="getThisField(item['@d'], 'name')"
          :textNumeric="true"
          :options="getThisField(item['@d'], 'options')"
          @set-value="setValue($event, item['@d'])"
        />
        <hs-formula
          v-else-if="
            item.e &&
            item.e === 'hs' &&
            getThisField(item['@d'], 'type') === 'number' &&
            getThisField(item['@d'], 'options').numberFormula
          "
          v-show="getThisFieldVisibility(item['@d'])"
          :id="`step-field-${getThisField(item['@d'], 'id')}`"
          :value="getThisField(item['@d'], 'value')"
          :placeholder="getThisField(item['@d'], 'name')"
          :options="getThisField(item['@d'], 'options')"
          :fields="$store.getters.cache.currentScript.fields"
          @set-value="setValue($event, item['@d'])"
        />
        <hs-datetime-picker
          v-else-if="item.e && item.e === 'hs' && getThisField(item['@d'], 'type') === 'date'"
          v-show="getThisFieldVisibility(item['@d'])"
          :id="`step-field-${getThisField(item['@d'], 'id')}`"
          :value="getThisField(item['@d'], 'value')"
          :placeholder="getThisField(item['@d'], 'name')"
          :locale="$i18n.locale"
          @set-value="setValue($event, item['@d'])"
        />
        <hs-select
          v-else-if="item.e && item.e === 'hs' && getThisField(item['@d'], 'type') === 'multilist'"
          v-show="getThisFieldVisibility(item['@d'])"
          :id="`step-field-${getThisField(item['@d'], 'id')}`"
          :value="getThisField(item['@d'], 'value')"
          :placeholder="getThisField(item['@d'], 'name')"
          :options="getThisFieldOptions(item['@d'])"
          @set-value="setValue($event, item['@d'])"
        />
        <span
          v-else-if="item.e && item.e === 'hs' && item['@c'].split(' ').some((e) => e === 'js_static_field')"
          class="hs-readonly-field"
        >
          {{ getStaticFieldValue(item['@d'], item) }}
        </span>
        <iframe
          v-else-if="item.e && item.e === 'hs' && item['@c'].split(' ').some((e) => e === 'js_embed_video')"
          width="100%"
          height="315"
          :src="`https://www.youtube.com/embed/${item['@d']}`"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          class="my-2 dynamic-media"
        />
        <span v-else>{{ item.t }}</span>
      </span>
    </span>
  </span>
</template>

<script>
// Vue renderless component
import './dynamic-tag.js'

import FieldsInScriptMixin from './_fields-in-script-mixin.js'
import { STATIC_FIELD_TYPES } from '@/core/types.js'

export default {
  name: 'StepRender',
  mixins: [FieldsInScriptMixin],
  props: {
    jsonText: {
      type: Array,
      default: null,
    },
    uid: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    tableType: '',
  }),
  methods: {
    getStaticFieldValue(type, item) {
      const key = STATIC_FIELD_TYPES[type]
      const label = {
        first_name: this.$t('pass.data.staticFields.firstName'),
        last_name: this.$t('pass.data.staticFields.lastName'),
        username: this.$t('pass.data.staticFields.nickname'),
        ip: this.$t('pass.data.staticFields.ip'),
      }
      if (!key) console.log('🐹 unknown field', type, item)
      return key
        ? this.$store.getters.auth.userInfo[key]
          ? this.$store.getters.auth.userInfo[key]
          : label[key]
        : `unknown field: ${type}`
    },
  },
  created() {
    this.tableType = window.innerWidth > 1024 ? 'md' : 'sm'
  },
}
</script>

<style lang="sass">
.richedit_answer
  font-style: italic
.richedit_comment
  color: gray
.richedit_warning
  color: red

.richedit_emotion_funny
  color: green
.richedit_emotion_sly
  color: gold
.richedit_emotion_care
  color: blue
.richedit_emotion_intrigue
  color: pink
.richedit_emotion_interrogative
  color: orange
</style>
