<template>
  <div>
    <div v-if="Object.values(currentFields).length > 0" class="grid gap-5">
      <div v-for="f in currentFields" :key="f.id" class="grid w-full">
        <go-input-material
          v-if="f.type === 'text'"
          :id="f.id"
          :value="f.value"
          :placeholder="f.name"
          :autocomplete="f.options.autocomplete"
          @set-value="setValue($event, f.id)"
        />
        <go-textarea-material
          v-else-if="f.type === 'textarea'"
          :id="f.id"
          :value="f.value"
          :placeholder="f.name"
          @set-value="setValue($event, f.id)"
        />
        <hs-table
          v-else-if="f.type === 'table'"
          :id="f.id"
          :value="f.value"
          :placeholder="f.name"
          :options="f.options"
          @set-value="setValue($event, f.id)"
        />
        <go-checkbox-material
          v-else-if="f.type === 'checkbox'"
          :id="f.id"
          :value="f.value"
          :placeholder="f.name"
          @set-value="setValue($event, f.id)"
        />
        <go-number-material
          v-else-if="f.type === 'number'"
          :id="f.id"
          :value="f.value"
          :placeholder="f.name"
          :options="f.options"
          :textNumeric="true"
          @set-value="setValue($event, f.id)"
        />
        <go-date-picker-material
          v-else-if="f.type === 'date'"
          :id="f.id"
          :value="f.value"
          :placeholder="f.name"
          :locale="$i18n.locale"
          @set-value="setValue($event, f.id)"
        />
        <go-select-material
          v-else-if="f.type === 'multilist'"
          :id="f.id"
          :value="f.value"
          :placeholder="f.name"
          :options="getThisFieldOptions(f.id)"
          @set-value="setValue($event, f.id)"
        />
      </div>
    </div>
    <div v-else class="opacity-50">
      <p class="font-normal text-sm">{{ $t('pass.data.empty') }}..</p>
    </div>
  </div>
</template>

<script>
import FieldsInScriptMixin from '../_fields-in-script-mixin.js'
import {
  GoCheckboxMaterial,
  GoInputMaterial,
  GoDatePickerMaterial,
  GoTextareaMaterial,
  GoNumberMaterial,
  GoSelectMaterial,
} from '@smpartners/hyperscript-pass-ui'

export default {
  components: {
    GoTextareaMaterial,
    GoCheckboxMaterial,
    GoInputMaterial,
    GoNumberMaterial,
    GoDatePickerMaterial,
    GoSelectMaterial,
  },
  mixins: [FieldsInScriptMixin],
  props: {
    uid: {
      type: String,
      default: null,
    },
  },
}
</script>
