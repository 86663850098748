import core from './application/views/application.vue'
import {
  GoCheckbox,
  GoInput,
  GoDatePicker,
  GoTextarea,
  GoNumber,
  GoFormula,
  GoSelect,
  GoTable,
  IconBase,
} from '@smpartners/hyperscript-pass-ui'

import featureToggling from './application/mixins/_feature-toggling-mixin.js'

/**
 * Styles
 */
import 'element-plus/dist/index.css'
import '@smpartners/hyperscript-pass-ui/style.css'
import './assets/sass/global.sass'

export default {
  install(app) {
    /**
     * Plugins
     */

    /**
     * My global components
     */
    app.component('icon-base', IconBase)
    // fields
    app.component('hs-input', GoInput)
    app.component('hs-textarea', GoTextarea)
    app.component('hs-table', GoTable)
    app.component('hs-checkbox', GoCheckbox)
    app.component('hs-number', GoNumber)
    app.component('hs-datetime-picker', GoDatePicker)
    app.component('hs-select', GoSelect)
    app.component('hs-formula', GoFormula)

    /**
     * My global mixins
     */
    app.mixin(featureToggling)

    // Registering core component globally
    app.component('hyperscript-pass-core', core)
  },
}
