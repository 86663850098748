<template>
  <div>
    <div v-if="currentQuickTransitions.length === 0">
      <p class="font-normal text-sm">{{ $t('pass.quickTransitions.empty') }}..</p>
    </div>
    <div v-else>
      <go-input-search
        id="quickTransitionsSearch"
        :value="searchValue"
        :placeholder="$t('search')"
        :icon-clear-title="$t('clear')"
        class="mb-2 lg:mb-5"
        @set-value="setValue($event)"
      />
      <div v-for="(item, idx) in currentQuickTransitionsWithSearch" :key="idx" class="notes-item">
        <vs-collapse>
          <vs-collapse-item
            :open="thisCategoryIsOpen(idx)"
            :on-callback="
              () => {
                toggleCategory(idx)
              }
            "
          >
            <template v-slot:header>
              <span slot="header" class="select-none font-medium text-base">
                {{ item.n }}
              </span>
            </template>
            <div
              v-for="i in item.s"
              :key="i.id"
              class="qt-item"
              :class="{ 'qt-is-active': currentStepDataId === i.target }"
            >
              <div class="qt-bg">
                <p
                  class="line-clamp-1 font-normal cursor-pointer text-base select-none"
                  @click="toggleQuickTransition(i.target)"
                >
                  {{ i.label }}
                </p>
              </div>
            </div>
          </vs-collapse-item>
        </vs-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import { GoInputSearch } from '@smpartners/hyperscript-pass-ui'
import VsCollapse from '@/core/components/vs-collapse/vs-collapse.vue'
import VsCollapseItem from '@/core/components/vs-collapse/vs-collapse-item.vue'

export default {
  components: {
    GoInputSearch,
    VsCollapse,
    VsCollapseItem,
  },
  data: () => ({
    searchValue: '',
  }),
  props: {
    currentQuickTransitions: {
      type: Array,
      default: () => [],
    },
    openQuickTransitionsCategories: {
      type: Array,
      default: () => [],
    },
    currentStepData: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    currentQuickTransitionsWithSearch() {
      return this.currentQuickTransitions
        .map((e) => {
          const items = e.s.filter((step) => step.label.toLowerCase().includes(this.searchValue.toLowerCase()))
          return {
            n: e.n,
            s: items,
            _flag: items.length > 0,
          }
        })
        .filter((e) => e._flag)
    },
    currentStepDataId() {
      return this.currentStepData?.step ? this.currentStepData.step.id : 'start'
    },
  },
  methods: {
    setValue(val) {
      this.searchValue = val
    },
    toggleQuickTransition(eventId) {
      /**
       * eventId - step id
       * null - link id (you don't need it for a quick transition)
       * true - flag that the button is a quick transition
       */
      // this.$parent.$parent.send(eventId, null, true)
      this.$emit('toggle-quick-transition', eventId)
      this.$store.dispatch('app/TOGGLE_TOOLBAR', false)
    },
    toggleCategory({ category_id }) {
      this.$emit('toggle-quick-transition-category', category_id)
    },
    thisCategoryIsOpen({ category_id }) {
      return !this.openQuickTransitionsCategories.some((el) => el === category_id)
    },
  },
}
</script>
