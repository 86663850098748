<template>
  <step-render
    :uid="uid"
    :jsonText="jsonText"
    :currentFields="currentFields"
    :getStaticFieldValue="getStaticFieldValue"
    :texts="texts"
    :setValueFn="setValueFn"
    :onFieldChange="fieldChangeHandler"
    :locale="$i18n.locale"
  />
</template>

<script>
import { StepRender } from '@smpartners/hyperscript-pass-ui'
import { STATIC_FIELD_TYPES } from '../../../types'
import { onFieldChange } from '@/core/infrastructure/messaging/messaging'
import { useI18n } from 'vue-i18n'
import store from '../../../../store'

export default {
  name: 'LocalStepRender',
  components: {
    StepRender,
  },
  props: {
    uid: {
      type: String,
      default: null,
    },
    jsonText: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      texts: {},
    }
  },
  computed: {
    currentFields() {
      const fields = this.$store.getters.cache.currentScript.fields
      return fields ? fields : {}
    },
  },
  methods: {
    setValueFn(id, value) {
      store.dispatch('cache/CHANGE_FIELD', { id, value, _uid: this.uid })
    },
    fieldChangeHandler(data) {
      onFieldChange(data)
    },
    getStaticFieldValue(type, item) {
      const key = STATIC_FIELD_TYPES[type]
      const label = {
        first_name: this.$t('pass.data.staticFields.firstName'),
        last_name: this.$t('pass.data.staticFields.lastName'),
        username: this.$t('pass.data.staticFields.nickname'),
        ip: this.$t('pass.data.staticFields.ip'),
      }
      if (!key) console.log('🐹 unknown field', type, item)
      return key
        ? this.$store.getters.auth.userInfo[key]
          ? this.$store.getters.auth.userInfo[key]
          : label[key]
        : `unknown field: ${type}`
    },
  },
  created() {
    const { t } = useI18n()
    this.texts = {
      noStepText: t('pass.noStepText'),
      cleanAllFields: t('components.table.cleanAllFields'),
    }
  },
}
</script>
