import mitt from 'mitt'

const emitter = mitt()

export const notificationBus = {
  notify(payload) {
    emitter.emit('notify', payload)
  },
  on(event, handler) {
    emitter.on(event, handler)
  },
  off(event, handler) {
    emitter.off(event, handler)
  },
}
