<template>
  <div class="flex-1 w-full h-screen overflow-y-auto overflow-x-hidden pl-8 pr-8 lg:pr-80 py-5">
    <div
      class="pass-space-for-header"
    />
    <div class="mb-5">
      <h2>{{ $t('pass.appropriateResponseIsMissing.final.responsesMissing') }}</h2>
      <p class="text-sm font-medium mt-1">
        {{ $t('pass.appropriateResponseIsMissing.final.helpToImprove') }}:
      </p>
    </div>
    <div>
      <div
        v-for="(log, idx) in isEmptyNoAnswerFields"
        :key="idx"
      >
        <local-step-render
          :uid="currentScript._uid"
          :json-text="log.step.text"
        />
        <div class="mt-5 mb-8 lg:mb-16">
          <hs-textarea-material
            v-if="checkNoAnswerFlag(log.step.id, log.field.scriptUid)"
            :id="log.step.id"
            :value="getNoAnswerValue(log.step.id, log.field.scriptUid)"
            :placeholder="$t('pass.appropriateResponseIsMissing.name')"
            @set-value="setNoAnswerValue($event, log.step.id, log.field.scriptUid)"
          />
        </div>
      </div>
    </div>
    <div
      v-if="err"
      class="mb-4 lg:mb-6"
    >
      <p class="text-hs-danger">
        {{ $t('pass.appropriateResponseIsMissing.fillIn') }} "<b>{{ $t('pass.appropriateResponseIsMissing.name') }}</b>".
      </p>
    </div>
    <div>
      <hs-main-button
        variant="primary"
        class="lg:w-72"
        @click="toggleSave"
      >
        {{ $t('pass.appropriateResponseIsMissing.save') }}
      </hs-main-button>
    </div>
    <div
      class="pass-space-for-header"
    />
  </div>
</template>


<script>
import LocalStepRender from '../local-step-render.vue'
import HsMainButton from '@/core/design-system/buttons/hs-main-button.vue'
import HsTextareaMaterial from '@/core/design-system/form-elements/hs-textarea-material.vue'

export default {
  components: {
    LocalStepRender,
    HsMainButton,
    HsTextareaMaterial,
  },
  data: () => ({
    isEmptyNoAnswerFields: [],
    err: false,
  }),
  computed: {
    currentScript() {
      return this.$parent.currentScript
    },
    currentHistoryLog() {
      return this.$parent.currentHistoryLog
    },
    doneWithRedirect() {
      return this.$parent.doneWithRedirect
    },
  },
  mounted() {
    this.isEmptyNoAnswerFields = this.foundFieldsNoAnswer()
  },
  methods: {
    foundFieldsNoAnswer() {
      // old
      // const stepIdsWithNoAnswer = this.$store.getters.pass?.passStack[this.currentScript._uid]?.unexpected.filter(e => e.value.trim().length === 0).map(e => e.id)
      // const log = stepIdsWithNoAnswer.map(e => ({
      //   step: this.currentScript.data.steps.find(step => step.id === e),
      // }))
      // NEW
      const dataWithNoAnswer = Object.entries(this.$store.getters.pass?.passStack)
        .filter(([, pass]) => pass.unexpected.some(e => e.value.trim().length === 0))
        .map(([key, pass]) => ({...pass, _uid: key}))
      const stackUnexpectedFields = []
      dataWithNoAnswer.forEach(data => {
        data.unexpected.forEach(field => {
          if (field.value.trim().length === 0) {
            stackUnexpectedFields.push({
              stepId: field.id,
              fieldToken: field.token,
              scriptUid: data._uid,
            })
          }
        })
      })
      // console.log('🐹 foundFieldsNoAnswer/dataWithNoAnswer', dataWithNoAnswer)
      const log = stackUnexpectedFields.map(e => ({
        step: this.$store.getters.cache.currentWorkspace[e.scriptUid].data.steps.find(step => step.id === e.stepId),
        field: e,
      }))
      return log
    },
    checkNoAnswerFlag(stepId, _uid) {
      return this.$parent.checkNoAnswerFlag(stepId, _uid)
    },
    getNoAnswerValue(stepId, _uid) {
      return this.$parent.getNoAnswerValue(stepId, _uid)
    },
    setNoAnswerValue(value, stepId, _uid) {
      this.$parent.setNoAnswerValue(value, stepId, _uid)
    },
    toggleSave() {
      this.isEmptyNoAnswerFields = this.foundFieldsNoAnswer()
      this.$nextTick(() => {
        if (this.isEmptyNoAnswerFields.length > 0) {
          this.err = true
        } else {
          this.$parent.done(this.doneWithRedirect)
        }
      })
    },
  },
}
</script>
